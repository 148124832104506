.custom-modal {
    border: none;
  }

  .custom-modal-content {
    border-radius: 5%;
    overflow: hidden;
  }

  .radio-bold input[type='radio'] {
   transform:scale(1.5) ;
  }